/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker, Switch, Table } from 'antd';
import moment from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DownOutlined, RightCircleOutlined } from '@ant-design/icons';

import { InputInterface, OnAddArguments } from '../../../utils/forms/FormInterface';
import useEffectExcludingMount from '../../../hooks/useEffectExcludingMount';
import allowTableShort from '../../../utils/TableShort';
import DefaultTableHeader from './DefaultTableHeader';
import callApi from '../../../Api/config';
import Dropdown from '../../../components/Dropdown';

import '../entity.scss';
import { AppRoutes, GarageStatus, NextFrom, PickupStatus } from '../../../constants/AppConstant';
import {
    PICK_UP_ENTITY,
    USERS_LIST_ENTITY,
    PAYMENT_ENTITY,
    SERVICE_LIST_ENTITY,
    GARAGE_COMMISSION_TYPE,
    USERS,
    MOODBOARD_SECTIONS,
    FEATURED_COLLECTION,
    CURATED_SPACES,
    CUSTOMIZABLE_PRODUCTS,
    COLLABORATION_SECTIONS,
    CATEGORY,
    COLLABS,
    PRODUCTS,
    STORIES,
    PRODUCT_CATEGORIES,
    CATALOGUE,
    ABOUT,
    SPACES,
    CONTACT,
    HEADER_INFO,
} from '../../../constants/EntityName';
import { ALL_ORDER_LIST_ENTITY } from '../../../constants/EntityName';
import { GARAGE_MANAGEMENT_ENTITY } from '../../../constants/EntityName';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { toastSuccess } from '../../../service/Toast';
import { formatDate } from '../../../utils/functions';

type FilterList = any;

interface AbstractLandingProps {
    headerName: string;
    entityName: string;
    tableHeaders: Array<InputInterface>;
    refreshData?: boolean;
    onAdd?: (argument1?: OnAddArguments) => void;
    onSlotClick?: any;
    addName?: string;
    entityId?: string;
    code?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    filtersList?: FilterList[];
    setFiltersList?: React.Dispatch<React.SetStateAction<FilterList[]>>;
    handleEdit?: (userData: any) => void;
    orderStatusQueryParams?: any;
    handleModal?: () => void;
    onSelect?: (arg: any) => any;
    paymentToggleStatus?: (status: any) => any | undefined;
    serviceToggleStatus?: (status: any) => any | undefined;
}

let previousPaginationCount = 0;

const validEntityNames = [
    `${ALL_ORDER_LIST_ENTITY}/requested`,
    `${ALL_ORDER_LIST_ENTITY}/active`,
    `${ALL_ORDER_LIST_ENTITY}/completed`,
    `${ALL_ORDER_LIST_ENTITY}/return`,
];

const AbstractLanding = (props: AbstractLandingProps) => {
    const { entityName, onSlotClick, disableSearch, filtersList, paymentToggleStatus, serviceToggleStatus } = props;
    const [apiListingData, setApiListingData] = useState<any>([] as any);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [totalCount, setTotalCount] = useState<number>();
    const [searchQuery, setSearchQuery] = useState<string>();
    const [searchPhoneQuery, setPhoneSearchQuery] = useState<string>();
    const [searchCityQuery, setCitySearchQuery] = useState<string>();
    const [searchStateQuery, setStateSearchQuery] = useState<string>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [toggleId, setToggleId] = useState('');

    const [selectedFilter, setSelectedFilter] = useState<Array<{ key: string; value: CheckboxValueType[] }>>([]);

    const location = useLocation();
    const garageId = location.search.slice(1);

    const validEntities = [
        PICK_UP_ENTITY,
        `${PICK_UP_ENTITY}/pickup/${PickupStatus.PICKUP_SCHEDULED}`,
        `${PICK_UP_ENTITY}/pickup/${PickupStatus.VEHICLE_IN_TRANSIT}`,
        `${PICK_UP_ENTITY}/pickup/${PickupStatus.VEHICLE_REACHED}`,
    ];

    let modalText = (
        <h3>
            Are you sure you want to Update Status of <span style={{ fontWeight: 'bold' }}>Payment</span>?
        </h3>
    );

    let apiURL = '';
    const paymentURL = `${PAYMENT_ENTITY}/${toggleId}`;
    const serviceURL = `${SERVICE_LIST_ENTITY}/${toggleId}`;

    if (entityName === PAYMENT_ENTITY) {
        apiURL = paymentURL;
        modalText = (
            <h3>
                Are you sure you want to Update <span className='bold'>Payment Status</span>?
            </h3>
        );
    } else if (entityName === SERVICE_LIST_ENTITY) {
        apiURL = serviceURL;
        modalText = (
            <h3>
                Are you sure you want to Update <span className='bold'>Service Status</span>?
            </h3>
        );
    }

    const handleConfirm = async () => {
        try {
            const res = await callApi(apiURL, 'PATCH');

            if (res) {
                toastSuccess('Status Updated Successfully.');
                setShowModal(false);
                getData();
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error accepting garage:', error);
        }
    };

    const handleToggle = (toggleId: any) => {
        setShowModal(true);
        setToggleId(toggleId);
    };

    const closeModal = () => {
        setShowModal(false);
        getData();
    };

    const getData = async (paginationEvent = pagination) => {
        const queryParams: any = {};
        let res: any;

        if (props.entityId && props.code) {
            queryParams[props.code] = props.entityId;
        }

        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }

        if (searchQuery) {
            if (entityName === AppRoutes.OrderList) {
                queryParams.orderId = searchQuery;
            } else {
                queryParams.search = searchQuery;
            }
        }

        if (selectedFilter.length) {
            selectedFilter.forEach((v) => {
                queryParams[v.key] = v.value.join(',');
            });
        }

        setLoading(true);

        if (!queryParams.page || !queryParams.limit) {
            res = await callApi(props.entityName, 'GET');
        } else {
            res = await callApi(props.entityName, 'GET', null, queryParams);
        }

        if (
            entityName === FEATURED_COLLECTION ||
            entityName === CURATED_SPACES ||
            entityName === CUSTOMIZABLE_PRODUCTS
        ) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
                imageInfo: i?.images[0]?.imageInfo,
            }));
            setApiListingData(res.data.data.results);
        }

        if (entityName === MOODBOARD_SECTIONS) {
            const resData = [res.data.data];

            res.data.data.results = resData.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === COLLABORATION_SECTIONS) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === CATEGORY) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.headerImage[0]?.desktopPngUrl,
                desktopWebPUrl: i?.headerImage[0]?.desktopWebPUrl,
                mobilePngUrl: i?.headerImage[0]?.mobilePngUrl,
                mobileWebPUrl: i?.headerImage[0]?.mobileWebPUrl,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === COLLABS) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
                title: i?.content[0]?.title,
                description: i?.content[0]?.description,
                name: i?.content[0].authorDetails?.name,
                designation: i?.content[0].authorDetails?.designation,
                imageInfo: i?.images[0]?.imageInfo,
                heroDesktopPngUrl: i?.heroImage?.desktopPngUrl,
                heroDesktopWebPUrl: i?.heroImage?.desktopWebPUrl,
                heroMobilePngUrl: i?.heroImage?.mobilePngUrl,
                heroMobileWebPUrl: i?.heroImage?.mobileWebPUrl,
                heroImageInfo: i?.heroImage?.imageInfo,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === PRODUCTS) {
            res.data.data.results = res?.data?.data?.results?.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
                availableColors: i?.availableColors.join(', '),
                tags: i?.tags.join(', '),
            }));
            setApiListingData(res.data.data.results);
        }

        if (entityName === SPACES) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
                imageInfo: i?.images[0]?.imageInfo,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === STORIES) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
                imageInfo: i?.images[0]?.imageInfo,
                title: i?.content[0]?.title,
                description: i?.content[0]?.description,
                publishDate: formatDate(i?.publishDate),
                heroDesktopPngUrl: i?.heroImage?.desktopPngUrl,
                heroDesktopWebPUrl: i?.heroImage?.desktopWebPUrl,
                heroMobilePngUrl: i?.heroImage?.mobilePngUrl,
                heroMobileWebPUrl: i?.heroImage?.mobileWebPUrl,
                heroImageInfo: i?.heroImage?.imageInfo,
                designation: i?.content[0]?.authorDetail?.designation,
                name: i?.content[0]?.authorDetails?.name,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === PRODUCT_CATEGORIES) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === CATALOGUE) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
                imageInfo: i?.images[0]?.imageInfo,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === ABOUT) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                desktopPngUrl: i?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.images[0]?.mobileWebPUrl,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === CONTACT) {
            res.data.data.results = res.data.data.results.map((i: any) => ({
                ...i,
                email: i?.customerCare?.email,
                mobile: i?.customerCare?.mobile,
                enEmail: i?.enquries?.email,
                enMobile: i?.enquries?.mobile,
                shEmail: i?.showroomDetails[0]?.email,
                shMobile: i?.showroomDetails[0]?.mobile,
                address: i?.showroomDetails[0]?.address,
                location: i?.showroomDetails[0]?.location,
                timing: i?.showroomDetails[0]?.timing,
                desktopPngUrl: i?.showroomDetails[0]?.images[0]?.desktopPngUrl,
                desktopWebPUrl: i?.showroomDetails[0]?.images[0]?.desktopWebPUrl,
                mobilePngUrl: i?.showroomDetails[0]?.images[0]?.mobilePngUrl,
                mobileWebPUrl: i?.showroomDetails[0]?.images[0]?.mobileWebPUrl,
                imageInfo: i?.showroomDetails[0]?.images[0]?.imageInfo,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === HEADER_INFO) {
            const obj: any = {
                __v: '__v',
                _id: '_id',
                createdAt: 'createdAt',
                deleted: 'deleted',
                updatedAt: 'updatedAt',
            };
            res.data.data.results = Object.entries(res.data.data[0])
                .filter(([key]: any) => !obj[key])
                .map(([key, value]: any) => ({
                    name: key,
                    description: value?.description ? value?.description.join(' : ') : '',
                    desktopPngUrl: value?.heroImage[0]?.desktopPngUrl,
                }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === `${GARAGE_MANAGEMENT_ENTITY}/application/${garageId}/${NextFrom.STAFF}`) {
            res.data.data.results = res.data.data.mainGarageDetails.map((i: any) => ({
                ...i,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === `${USERS_LIST_ENTITY}`) {
            res.data.data.results = res.data.data.results?.map((i: any) => ({
                ...i,
                phone: `+${i.countryCode} - ${i.phone}`,
            }));

            setApiListingData(res.data.data.results);
        }

        if (entityName === USERS) {
            res.data.data.results = res.data.data.users;
        }

        if (res?.status && res?.data?.data?.results) {
            const apiData = res?.data?.data;

            const getTotalCount = () => {
                if ('hasMore' in apiData) {
                    const totalCount = apiData.hasMore === true ? (+apiData.page + 1) * 10 : +apiData.page * 10;

                    return totalCount;
                }

                return res?.data?.data.totalItems;
            };

            const totalCount = getTotalCount();
            setPaginationCount(totalCount);
            if (validEntities.includes(entityName)) {
                res.data.data.items = res.data.data.items.map((i: any) => ({
                    ...i,
                    assignThirdParty: 'Assign Third Party',
                    vehicleDetails: `${i.make} - ${i.model}`,
                    customerInfo: `${i.name} - ${i.number}`,
                    pickupDetails: (
                        <div>
                            <span>{`${moment(i.date).format('Do MMM YYYY')}`}</span>
                            <br />
                            <span>{`${i.timeSlot}`}</span>
                        </div>
                    ),
                }));
            }

            if (validEntityNames.includes(entityName)) {
                res.data.data.items = res.data.data.items.map((i: any) => ({
                    ...i,
                    orderDetails: (
                        <div>
                            <span>{`${i.bookingId}`}</span>
                            <br />
                            <span>{`${i.bookingDate}`}</span>
                        </div>
                    ),
                    customerInfo: `${i.name} - ${i.number}`,
                    vehicleDetails: `${i.make} - ${i.model}`,
                    pickupDetails: (
                        <div>
                            <span>{`${i.pickupAddress}`}</span>
                            <br />
                            <span>{`${i.bookingDate}`}</span>
                            <br />
                            <span>{`${i.timeSlot}`}</span>
                        </div>
                    ),
                }));
            }

            if (entityName === `${GARAGE_MANAGEMENT_ENTITY}/${GarageStatus.ACCEPTED}`) {
                res.data.data.results = res.data.data.results.map((i: any) => ({
                    ...i,
                    email: 'Blacklist',
                    customerInfo: (
                        <div>
                            <span>{`Name - ${i.contactPersonName}`}</span>
                            <br />
                            <span>{`Phone - ${i.phone}`}</span>
                            <br />
                            <span>{`Email - ${i.email}`}</span>
                        </div>
                    ),
                    fullAddress: (
                        <div>
                            <span>{`${i.buildingNumber}`}</span>
                            <br />
                            <span>{`${i.address}`}</span>
                            <br />
                            <span>{`${i.city}`}</span>
                        </div>
                    ),
                }));
            }

            if (entityName === `${GARAGE_MANAGEMENT_ENTITY}/${GarageStatus.NEW}`) {
                res.data.data.results = res.data.data.results.map((i: any) => ({
                    ...i,
                    phone: 'View Application',
                    customerInfo: (
                        <div>
                            <span>{`Name - ${i.contactPersonName}`}</span>
                            <br />
                            <span>{`Phone - ${i.phone}`}</span>
                            <br />
                            <span>{`Email - ${i.email}`}</span>
                        </div>
                    ),
                    fullAddress: (
                        <div>
                            <span>{`${i.buildingNumber}`}</span>
                            <br />
                            <span>{`${i.address}`}</span>
                            <br />
                            <span>{`${i.city}`}</span>
                        </div>
                    ),
                }));
            }

            if (entityName === `${GARAGE_MANAGEMENT_ENTITY}/${GarageStatus.BLACKLISTED}`) {
                res.data.data.results = res.data.data.results.map((i: any) => ({
                    ...i,
                    customerInfo: (
                        <div>
                            <span>{`Name - ${i.contactPersonName}`}</span>
                            <br />
                            <span>{`Phone - ${i.phone}`}</span>
                            <br />
                            <span>{`Email - ${i.email}`}</span>
                        </div>
                    ),
                    fullAddress: (
                        <div>
                            <span>{`${i.buildingNumber}`}</span>
                            <br />
                            <span>{`${i.address}`}</span>
                            <br />
                            <span>{`${i.city}`}</span>
                        </div>
                    ),
                }));
            }

            if (entityName === `${PICK_UP_ENTITY}/pickup`) {
                res.data.data.results = res.data.data.results.map((i: any) => ({
                    ...i,
                    vehicleDetails: `${i?.make} - ${i?.model}`,
                    customerInfo: `${i?.name} - ${i?.number}`,
                    pickupDetails: (
                        <div>
                            <span>{`${i.bookingDate}`}</span>
                            <br />
                            <span>{`${i.timeSlot}`}</span>
                        </div>
                    ),
                }));
            }

            setApiListingData(res.data.data.results);
            setTotalCount(totalCount);
        }

        setLoading(false);
    };

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const onPageChange = async (event: any) => {
        setPagination(event);
        getData(event);
    };

    const onSummitFilter = (filterName: string, list: CheckboxValueType[]) => {
        const newFilter = selectedFilter.filter((value) => value.key !== filterName);

        if (list.length) {
            newFilter.push({
                key: filterName,
                value: list,
            });
        }
        setSelectedFilter(newFilter);
    };

    useEffectExcludingMount(() => {
        if (searchQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchPhoneQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchCityQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchStateQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
    }, [searchQuery, searchPhoneQuery, searchCityQuery, searchStateQuery]);

    useEffectExcludingMount(() => {
        getData();
    }, [props.refreshData]);

    // THis is default api call for first time also
    useEffect(() => {
        getData({ ...pagination, current: 1 });
    }, [selectedFilter, entityName]);

    useEffect(() => {
        if (props.orderStatusQueryParams) {
            onSummitFilter('orderStatus', [props.orderStatusQueryParams]);
        }
    }, [props.orderStatusQueryParams]);

    const onAddClick = (record: any) => {
        if (props.onAdd) {
            props.onAdd(record);
        }
    };

    const getFilter = () => (
        <div className='flex'>
            {filtersList?.map((filter) => {
                switch (filter.filterType) {
                    case 'DatePicker':
                        return (
                            <DatePicker
                                key={filter.filterKey}
                                allowClear
                                suffixIcon={<DownOutlined />}
                                className='date-filter'
                                format='YYYY-MM-DD'
                                name={filter.filterName}
                                placeholder={filter.placeholder}
                                onChange={(_, dateString) => onSummitFilter(filter.key, [dateString])}
                            />
                        );
                    default:
                        return (
                            <Dropdown
                                checkBoxOptions={filter.options}
                                onSubmitFilter={onSummitFilter}
                                filterName={filter.filterName}
                                filterKey={filter.filterKey}
                                key={filter.filterKey}
                            />
                        );
                }
            })}
        </div>
    );

    const columns: any = props.tableHeaders.map((tableMeta: any) => ({
        key: tableMeta.tableKey,
        title: tableMeta.tableLabel,
        dataIndex: tableMeta.tableKey,
        // eslint-disable-next-line no-nested-ternary
        colSpan: tableMeta.type === 'image' ? 0 : tableMeta.type === 'combinedColumn' ? 2 : null,
        onCell: (record: any) => ({
            style: {
                cursor:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'pointer'
                        : 'default',
                textDecoration:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'underline'
                        : '',
            },
            onClick: () => {
                if (tableMeta.allowAddRecordClick) {
                    // This code may be used in future
                    // if (routesEntityArray.includes(entityName)) {
                    //     history.push(`${entityName}/${record?.id}`);
                    // } else {
                    onAddClick(record);
                    // }
                }

                // if (tableMeta.allowAddRedirectOnClicks) {
                //     history.push(`${ORDER_LIST_ENTITY}/${record.order.id}`);
                // }

                if (tableMeta.allowSlotRecordClick) {
                    const tableMetaUrl = tableMeta.url;

                    if (tableMetaUrl) {
                        history.push(`${entityName}/${record?.id}/${tableMetaUrl}`);
                    } else {
                        onSlotClick?.(tableMeta.tableKey, { id: record.id, name: record.name });
                    }
                }
            },
        }),

        sorter: allowTableShort(tableMeta.enableSort, tableMeta.tableKey),

        render: (value: any, record: any) => {
            if (tableMeta.columnType === 'userName') {
                return `${record.firstName} ${record.lastName}`;
            }

            if (tableMeta.columnType === 'orderIdHash') {
                return `${record.order.orderIdHash}`;
            }
            if (tableMeta.columnType === 'orderId') {
                return `${record.order.id}`;
            }

            if (tableMeta.columnType === 'countriesName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.countries?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'displayName') {
                // when Items are deleted then the corresponding inventories are not deleted. In that case the value will be undefined and hence the optional chaining
                return value?.name || '--';
            }

            if (tableMeta.columnType === 'servicesObjectArray') {
                return value
                    ?.map(
                        (services: { level: string; serviceDetail: string; serviceDetailLevel: string }) =>
                            `${services?.level} - ${services?.serviceDetail} - ${services?.serviceDetailLevel}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'facebookLink') {
                return `${record.socialMediaLinks.facebook} `;
            }
            if (tableMeta.columnType === 'twitterLink') {
                return `${record.socialMediaLinks.twitter}`;
            }

            if (tableMeta.columnType === 'contactNumbersMultiple') {
                return `${record.contactNumbers.join(' | ')}`;
            }

            if (tableMeta.columnType === 'documentsDisplayNameObject') {
                return (
                    record?.documents
                        ?.map((documentDisplayNameObject: { name: string }) => documentDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'featureDisplayNameObject') {
                return (
                    record?.features
                        ?.map(
                            (featuresDisplayNameObject: { description: string }) =>
                                featuresDisplayNameObject?.description,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceAttributesDisplayNameObject') {
                return (
                    record?.serviceAttributes
                        ?.map(
                            (serviceAttributeDisplayNameObject: { name: string }) =>
                                serviceAttributeDisplayNameObject?.name,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'plansDisplayNameObject') {
                return (
                    record?.plans
                        ?.map((planDisplayNameObject: { title: string }) => planDisplayNameObject?.title)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'parametersDisplayNameObject') {
                return (
                    record?.parameters
                        ?.map((parametersDisplayNameObject: { name: string }) => parametersDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'categoryName') {
                return `${record?.category?.title || '--'}`;
            }

            if (tableMeta.columnType === 'titleName') {
                return `${record?.make?.title || '--'}`;
            }

            if (tableMeta.columnType === 'FuelTypeName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.fuelType?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'discountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.discountType || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'referralDiscountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.couponTarget || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'commissionType') {
                // In that case the value will be undefined and hence the optional chaining
                const commissionTypeValue = record?.commissionType || '--';
                return commissionTypeValue.charAt(0).toUpperCase() + commissionTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'displayNameTitle') {
                return `${record?.title?.heading || '--'}`;
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'displayNameObjectArray') {
                return value
                    ?.map(
                        (displayNameObject: { name: string; mobile: string }) =>
                            `${displayNameObject?.name} - ${displayNameObject?.mobile}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'password') {
                return value?.password || '********';
            }

            if (tableMeta.columnType === 'clickHere') {
                return <RightCircleOutlined />;
            }

            if (tableMeta.columnType === 'date') {
                return moment.utc(value).toString();
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'active') {
                return value ? 'Yes' : 'No';
            }

            if (tableMeta.columnType === 'image') {
                return (
                    <div>
                        {value && (
                            <img
                                src={value}
                                style={{ borderRadius: '50%', height: '40px', width: '40px', overflow: 'hidden' }}
                            />
                        )}
                    </div>
                );
            }

            if (tableMeta.columnType === 'dropDown') {
                return (
                    <select
                        onChange={props.onSelect}
                        name={tableMeta.name}
                        defaultValue={record.status}
                        style={{
                            border: '1px solid grey',
                            outline: 'none',
                            borderRadius: '5px',
                            padding: '2px',
                            cursor: 'pointer',
                        }}>
                        {tableMeta.option.map((o: any) => {
                            if (record.status === 'pickup-scheduled') {
                                return (
                                    <option value={o.value} key={o.value} className='custom-option'>
                                        {o.name}
                                    </option>
                                );
                            } else if (record.status === 'vehicle-in-transit' && o.value === 'vehicle-in-transit') {
                                return (
                                    <option
                                        value={o.value}
                                        key={o.value}
                                        className='custom-option'
                                        style={{
                                            border: 'none',
                                            background: 'inherit',
                                            outline: 'none',
                                            cursor: 'pointer',
                                            backgroundColor: o.value === record.status ? 'inherit' : 'inherit',
                                        }}>
                                        {o.name}
                                    </option>
                                );
                            } else if (record.status === 'vehicle-reached' && o.value === 'vehicle-reached') {
                                return (
                                    <option
                                        value={o.value}
                                        key={o.value}
                                        className='custom-option'
                                        style={{
                                            border: 'none',
                                            background: 'inherit',
                                            outline: 'none',
                                            cursor: 'pointer',
                                            backgroundColor: o.value === record.status ? 'inherit' : 'inherit',
                                        }}>
                                        {o.name}
                                    </option>
                                );
                            }
                        })}
                    </select>
                );
            }

            if (tableMeta.columnType === 'toggle' && entityName === PAYMENT_ENTITY) {
                return (
                    <Switch
                        style={{ backgroundColor: record.paymentStatus ? '#597ef7' : '#bfbfbf' }}
                        defaultChecked={record.paymentStatus}
                        checked={record.paymentStatus}
                        onChange={() => handleToggle(record?.paymentId)}
                    />
                );
            }
            if (tableMeta.columnType === 'toggle' && entityName === SERVICE_LIST_ENTITY) {
                return (
                    <Switch
                        style={{ backgroundColor: record.status ? '#597ef7' : '#bfbfbf' }}
                        defaultChecked={record.status}
                        checked={record.status}
                        onChange={() => handleToggle(record?.serviceDetailId)}
                    />
                );
            }

            return value ?? '--';
        },
    }));

    const getHeader = () => (
        <DefaultTableHeader
            headerName={props.headerName}
            totalCount={totalCount}
            countText={props.headerName === 'Experts' ? 'Profiles' : 'Items'}
            onAdd={() => props.onAdd && props.onAdd()}
            setSearchQuery={setSearchQuery}
            setPhoneSearchQuery={setPhoneSearchQuery}
            setCitySearchQuery={setCitySearchQuery}
            setStateSearchQuery={setStateSearchQuery}
            addName={props.addName ? props.addName : ''}
            disableAdd={props.disableAdd}
            disableSearch={disableSearch}
        />
    );

    return (
        <div className='landing-container'>
            {getHeader()}
            {/* This is the dropdown for filter in listing UI */}
            <div className='dropdown-group'>{getFilter()}</div>
            <Table
                columns={columns}
                loading={loading}
                dataSource={apiListingData}
                onChange={onPageChange}
                pagination={{ ...pagination, total: totalCount }}
                scroll={{ x: 'max-content' }}
                style={{ minHeight: '100vh' }}
                bordered
            />
            {showModal && (
                <ConfirmationModal
                    isOpen={showModal}
                    onclose={closeModal}
                    modalText={modalText}
                    handleConfirmation={handleConfirm}
                />
            )}
        </div>
    );
};

AbstractLanding.defaultProps = {
    refreshData: false,
};

export default AbstractLanding;
