/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Tooltip, Upload, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { BASE_API_URL } from '../../env';

import './style.scss';

export const payloadUrlName = ['desktopPngUrl', 'desktopWebPUrl', 'mobilePngUrl', 'mobileWebPUrl'];

const GalleryImageUpload = (props: any) => {
    const { formInput, index, form, isCreateMode } = props;
    const formFieldValue = form.getFieldValue(formInput.name);
    const [fileList, setFileList] = useState<any>([]);
    const [isDelete, setIsdelete] = useState(false);

    function getFileNameInIndex() {
        const obj: any = {};
        payloadUrlName.forEach((name: string, idx) => {
            const key = `${formInput.name}--${name}`;
            const file = form.getFieldValue(key);
            if (Array.isArray(file)) {
                const value = `${index + '' + idx}`;
                obj[value] = value;
            }
            console.log({ key, file });
        });

        return obj;
    }
    const [fileNameInIndex, setFileNameInIndex] = useState<any>(getFileNameInIndex);

    const onChange = (info: any, index: string, idx: number) => {
        const { fileList, file } = info;
        const { status, size } = file;

        switch (status) {
            case 'uploading': {
                const fileSizeCheck = (size: number, allowedSize?: number) => {
                    if (!allowedSize) return true;

                    return size / 1024 < allowedSize;
                };

                const isUploadAllowed = fileSizeCheck(size, formInput.fieldType.maxFileSize);

                if (isUploadAllowed) {
                    setFileList((prev: any) => {
                        const newList = [...prev];
                        const existingIndex = newList.findIndex((item) => item.name === index);
                        if (existingIndex !== -1) {
                            newList[existingIndex] = { ...file, name: index, idx };
                        } else {
                            newList.push({ ...file, name: index, idx });
                        }
                        return newList;
                    });
                }
                break;
            }

            case 'removed': {
                setFileList(fileList);
                setFileNameInIndex((prev: any) => {
                    delete prev[index];
                    return { ...prev };
                });
                setIsdelete(true);

                break;
            }

            case 'done': {
                const res: any = {
                    status: 'done',
                    url: file.response?.data || file.url,
                    name: index,
                    uid: file.uid,
                    thumbUrl: file.response?.data || file.url,
                    idx,
                };
                setFileNameInIndex((prev: any) => ({ ...prev, [index]: index }));
                setFileList((prev: any) => {
                    const newList = [...prev];
                    const existingIndex = newList.findIndex((item) => item.name === index);
                    if (existingIndex !== -1) {
                        newList[existingIndex] = res;
                    } else {
                        newList.push({ ...file, idx });
                    }
                    return newList;
                });
                message.success(`${info.file.name} file uploaded successfully.`);
                break;
            }

            case 'error': {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    useEffect(() => {
        fileList.forEach((file: any) => {
            const key = `${formInput.name}--${payloadUrlName[file.idx]}`;

            form.setFieldValue(key, [
                {
                    url: file.url,
                    uid: file.uid,
                },
            ]);
        });
    }, [fileList]);

    const onPreview = async (file: any) => {
        let src = file?.response?.data || file?.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadButton = (idx: number) => (
        <button style={{ border: 0, background: 'none', width: '400px' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{tooltipMessages[idx]}</div>
        </button>
    );

    // probably this function is not being used
    const setFileValue = () => {
        const data: any = [];
        const _fileNameInIndex: any = {};
        const listData = formFieldValue?.map((obj: any, idx: number) => {
            const object1: any = {};
            let index = 0;
            Object.entries(obj).map((ele: any) => {
                const [key, value] = ele;
                if (payloadUrlName.includes(key)) {
                    const object2 = {
                        uid: idx + '' + index,
                        status: 'done',
                        thumbUrl: value,
                        name: key,
                        response: { data: value },
                    };
                    _fileNameInIndex[idx + '' + index] = idx + '' + index;
                    object1['image' + (index + 1)] = [object2];
                    data.push(object2);
                    index = index + 1;
                }
            });
            if (obj['imageInfo']) {
                object1['input' + idx] = obj['imageInfo'];
            }
            return object1;
        });

        form.setFieldsValue({ [formInput.name]: listData });
        return { indexList: _fileNameInIndex, fileList: data };
    };

    // probably this useEffect is not being used
    useEffect(() => {
        const firstObject = formFieldValue ? formFieldValue[0] : null;

        if (!isCreateMode && !isDelete && firstObject && firstObject['desktopPngUrl']) {
            const { indexList, fileList } = setFileValue();

            // Check if file names have changed before updating state
            const hasFileNamesChanged = Object.keys(indexList).some((key) => indexList[key] !== fileNameInIndex[key]);

            if (hasFileNamesChanged) {
                setFileNameInIndex(indexList);
                setFileList(() => fileList);
            }
        }
    }, [form.getFieldValue(formInput.name), isCreateMode]);

    const tooltipMessages = [
        'Upload desktop PNG image',
        'Upload desktop WebP image',
        'Upload mobile PNG image',
        'Upload mobile WebP image',
    ];

    return (
        <Form.Item
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={index === 0 ? <div>{formInput.label}</div> : <div></div>}
            labelAlign='left'
            colon={false}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }} className='imageUploadContainer'>
                <div>{formInput.fieldType.topCaption}</div>
                <div style={{ display: 'flex', columnGap: '8px' }} className='imageUploadContainer'>
                    {[1, 2, 3, 4].map((num, idx: any) => {
                        console.log({ value: fileNameInIndex[index + '' + idx] });
                        return (
                            <Tooltip key={num} title={tooltipMessages[idx]} placement='top'>
                                <Form.Item
                                    key={num}
                                    style={{ marginBottom: '0px', width: '100%' }}
                                    name={`${formInput.name}--${payloadUrlName[idx]}`}
                                    valuePropName='fileList'
                                    getValueFromEvent={(e) => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        return e && e.fileList;
                                    }}
                                    rules={[{ required: true, message: 'Please upload an image' }]}>
                                    <Upload
                                        name={`displayImage`}
                                        listType='picture-card'
                                        fileList={fileList}
                                        action={`${BASE_API_URL}homepage/upload`}
                                        onChange={(info: any) => onChange(info, index + '' + idx, idx)}
                                        onPreview={onPreview}>
                                        {fileNameInIndex[index + '' + idx] ? null : uploadButton(idx)}
                                    </Upload>
                                </Form.Item>
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        </Form.Item>
    );
};

export default GalleryImageUpload;
