import { errorMsg } from '../constants/messages';

const CatalogueQueryFormData = [
    {
        title: 'Catalogue Request Details',
        name: 'catalogueId',
        label: 'Catalogue Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Catalogue Id', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'fullName',
        label: 'Fullname',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Fullname', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'contact',
        label: 'Contact',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'company',
        label: 'Company',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'profession',
        label: 'Profession',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Profession' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'projectname',
        label: 'Project Name',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Project Name' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'location',
        label: 'Location',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Location' },
        isTableHeader: true,
        isAccordian: false,
    },
];

export { CatalogueQueryFormData };
