import { useState } from 'react';
import { Button } from 'antd';

import GenericAccordian from '../../components/GenericAccordian/GenericAccordian';
import { getFormDefaultValue, getTableHeaders } from '../../utils/forms/FormDataModel';
import { ContentItem, OnAddArguments, TabContent } from '../../utils/forms/FormInterface';
import AbstractForm from '../Entity/AbstractForm';
import AbstractLanding from '../Entity/AbstractLanding';
import AbstractTabs, { TabActiosEventType, TabActiosProps } from '../Entity/AbstractTabs';
import callApi from '../../Api/config';
import { Newsletter } from '../../constants/EntityName';
import { NewsletterDownload } from '../../constants/EntityName';
import { NewsletterFormData } from '../../datamodels/newsletter';

const NewsletterFlow = () => {
    const [activeKey, setActiveKey] = useState<string>('0');
    const [userName, setUserName] = useState<string>();
    const [showFormComponent, setShowFormComponent] = useState<boolean>(false);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [content, setContent] = useState<TabContent>([]);

    const defaultFormValue: any = getFormDefaultValue(NewsletterFormData);
    const tableHeaders: any = getTableHeaders(NewsletterFormData);
    const addName = 'newsletter';
    const headerName = 'Newsletter';

    const onSuccessful = (id?: string) => {
        if (id === 'create') {
            setShowFormComponent(false);
        }
        onTabClick('0');
        setRefreshData((prev) => !prev);
    };

    const onAddNew = (argument1?: OnAddArguments) => {
        if (argument1) {
            const checkArr = content.filter((item: ContentItem) => item.key === argument1.id);
            checkArr.length ? null : onAdd(argument1);
        } else {
            setShowFormComponent(true);
            setUserName('Add Job Posting');
            onTabClick('a');
        }
    };

    const closeTab = (key: string, shouldRefresh: boolean) => {
        setContent((content) => content.filter((item) => item.key !== key));
        setActiveKey('0');
        shouldRefresh && setRefreshData((prev) => !prev);
        key === 'a' && setShowFormComponent(false);
    };

    const onAdd = (argument1: OnAddArguments) => {
        const newObj = {
            key: argument1._id,
            tabTitle: argument1._id,
            content: (
                <GenericAccordian key={content.length}>
                    <AbstractForm
                        entityId={argument1._id}
                        isCreateMode={false}
                        headerName={headerName}
                        entityName={Newsletter}
                        formTemplate={NewsletterFormData}
                        formDefaultPayload={getFormDefaultValue(NewsletterFormData)}
                        onSuccessfulUpdate={onSuccessful}
                        formHeader=''
                        showDeleteDraft={true}
                        closeTab={closeTab}
                    />
                </GenericAccordian>
            ),
        };

        // It is to make the newly added tab as active
        setContent((prev: any) => [...prev, newObj]);
        argument1._id && setActiveKey(String(argument1._id));
    };

    const onTabClick = (key: string) => {
        setActiveKey(key);
    };

    const updateFormVisibility = (action: TabActiosProps, targetKey?: string | TabActiosEventType) => {
        if (action === 'remove') {
            if (targetKey === 'a') {
                setShowFormComponent(false);
                onTabClick('0');
            } else {
                onTabClick('0');
                // whenever a tab is closed, that tab is filtered out from the list of tabs which are rendered inside the AbstractTab component
                setContent((content) => content.filter((item) => String(item.key) !== targetKey));
            }
        }
    };

    async function onDownload() {
        const resp: any = await callApi(NewsletterDownload, 'GET', null, null, null, 'downloadAPI');
        const url = URL.createObjectURL(resp.data);
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.download = 'newsletter.csv';
        aTag.click();
    }

    const extraButton = (
        <div className='addBtn'>
            <Button onClick={onDownload}>Download Emails</Button>
        </div>
    );

    return (
        <AbstractTabs
            activeKey={activeKey}
            entityName='Newsletter'
            updateFormVisibility={updateFormVisibility}
            onTabClick={onTabClick}
            secondTabTitle={userName}
            showFormComponent={showFormComponent}
            content={content}
            formComponent={
                <GenericAccordian>
                    <AbstractForm
                        isCreateMode={true}
                        headerName={headerName}
                        entityName={Newsletter}
                        formTemplate={NewsletterFormData}
                        formDefaultPayload={defaultFormValue}
                        onSuccessfulUpdate={onSuccessful}
                        closeTab={closeTab}
                    />
                </GenericAccordian>
            }>
            <AbstractLanding
                headerName={headerName}
                addName={addName}
                entityName={Newsletter}
                tableHeaders={tableHeaders}
                onAdd={onAddNew}
                refreshData={refreshData}
                disableAdd
                extraButton={extraButton}
            />
        </AbstractTabs>
    );
};

export default NewsletterFlow;
