/* eslint-disable no-unused-vars */
import { Button } from 'antd';
import { ReactElement, useEffect, useState } from 'react';

import Search from '../../../components/Search/Search';
import SearchByCity from '../../../components/Search/SearchByCity';
import SearchByPhone from '../../../components/Search/SearchByPhone';
import SearchByState from '../../../components/Search/SearchByState';

interface IDefaultTableHeader {
    headerName?: string;
    totalCount?: number;
    countText?: string;
    setSearchQuery: (value: any) => void;
    setPhoneSearchQuery: (value: any) => void;
    setCitySearchQuery: (value: any) => void;
    setStateSearchQuery: (value: any) => void;
    onAdd: () => void;
    addName?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    extraButton?: ReactElement;
}

const DefaultTableHeader = (props: IDefaultTableHeader) => {
    const { disableSearch, extraButton } = props;
    const [garageSearch, setGarageSearch] = useState('');

    useEffect(() => {
        if (props.addName === 'Garage Commission') {
            setGarageSearch('Garage');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const getSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order' || value === 'Refunds') {
            return 'Order Id';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getPhoneSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'Phone No';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getCitySearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'City Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getStateSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'State Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    return (
        <div className='actionbar'>
            <div className='flex align-bottom'>
                <h2>{props.headerName}</h2>
                <span>
                    {props.totalCount} {props.countText ? props.countText : 'Items'}
                </span>
            </div>
            <div className='flex align-center'>
                {!disableSearch && (
                    <Search
                        setSearchQuery={props.setSearchQuery}
                        placeholderValue={getSearchPlaceholder(!garageSearch ? props.addName : garageSearch)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByPhone
                        setPhoneSearchQuery={props.setPhoneSearchQuery}
                        placeholderValue={getPhoneSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                {props.headerName === 'Order List' && (
                    <SearchByCity
                        setCitySearchQuery={props.setCitySearchQuery}
                        placeholderValue={getCitySearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByState
                        setStateSearchQuery={props.setStateSearchQuery}
                        placeholderValue={getStateSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                <div className='addBtn'>
                    <Button disabled={props.disableAdd} onClick={props.onAdd}>
                        + Add {props.addName}
                    </Button>
                </div>
                {extraButton}
                {(props.headerName === 'Option Type' || props.headerName === 'Collection Type') && (
                    <div className='addBtn'>
                        <Button
                            onClick={() => {
                                setModalVisible(true);
                            }}>
                            Change {props.headerName} position
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

DefaultTableHeader.defaultProps = {
    addName: '',
};

export default DefaultTableHeader;
