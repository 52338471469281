/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Tooltip, Upload, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import './style.scss';
import type { UploadFile } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/lib/upload';

import { BASE_API_URL } from '../../env';
import { dropDownOptions } from '../../constants/AppConstant';

interface MultiSpatialImageUploadProps {
    formInput: {
        name: string;
        label: string;
        fieldType: {
            maxFileSize: number;
        };
        readonly?: boolean;
    };
    index: number;
    form: any;
    isCreateMode: boolean | undefined;
}

interface FileNameIndex {
    [key: string]: string;
}

const MultiSpatialImageUpload: React.FC<MultiSpatialImageUploadProps> = ({ formInput, index, form, isCreateMode }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileNameInIndex, setFileNameInIndex] = useState<FileNameIndex>({});
    const [isDelete, setIsdelete] = useState(false);

    const formFieldValue = form.getFieldValue('specialImages');

    const onChange = (info: UploadChangeParam<UploadFile>, index: string) => {
        const { fileList, file } = info;
        const { status, size } = file;

        switch (status) {
            case 'uploading': {
                const fileSizeCheck = (size: number | undefined, allowedSize?: number) => {
                    if (!allowedSize) return true;
                    return size && size / 1024 < allowedSize;
                };

                const isUploadAllowed = fileSizeCheck(size, formInput.fieldType.maxFileSize);

                if (isUploadAllowed) {
                    setFileList((prev: UploadFile[]) => {
                        const newList = [...prev];
                        const existingIndex = newList.findIndex((item) => item.name === index);
                        if (existingIndex !== -1) {
                            newList[existingIndex] = file;
                        } else {
                            newList.push(file);
                        }
                        return newList;
                    });
                }
                break;
            }

            case 'removed': {
                form.getFieldValue('images');
                setFileList(fileList);
                setFileNameInIndex((prev: FileNameIndex) => {
                    delete prev[index];
                    return { ...prev };
                });
                setIsdelete(true);

                break;
            }

            case 'done': {
                const res: UploadFile = {
                    status: 'done',
                    url: file.response?.data || file.url,
                    name: index,
                    uid: file.uid,
                    thumbUrl: file.response?.data || file.url,
                };

                setFileNameInIndex((prev: FileNameIndex) => ({ ...prev, [index]: index }));
                setFileList((prev: UploadFile[]) => {
                    const newList = [...prev];
                    const existingIndex = newList.findIndex((item) => item.name === index);
                    if (existingIndex !== -1) {
                        newList[existingIndex] = res;
                    } else {
                        newList.push(res);
                    }
                    return newList;
                });
                message.success(`${info.file.name} file uploaded successfully.`);
                break;
            }

            case 'error': {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const setFileValue = () => {
        const payloadUrlName = ['desktopPngUrl', 'desktopWebPUrl', 'mobilePngUrl', 'mobileWebPUrl'];
        const data: UploadFile[] = [];
        const _fileNameInIndex: FileNameIndex = {};

        const listData = formFieldValue?.map((obj: any, idx: number) => {
            const { urls, meta } = obj;
            const object1: any = {};

            Object.entries(urls[0] as Record<string, string>).forEach(([key, value], index: number) => {
                if (payloadUrlName.includes(key)) {
                    const object2: UploadFile = {
                        uid: `${idx}-${index}`,
                        status: 'done',
                        thumbUrl: value,
                        url: value,
                        name: `${idx}${index}`,
                        response: { data: value },
                    };
                    _fileNameInIndex[`${idx}${index}`] = `${idx}${index}`;
                    object1[`image${index + 1}`] = [object2];
                    data.push(object2);
                }
            });

            if (meta) {
                object1[`input${idx}`] = meta.category;
                object1[`dropdown${idx}`] = meta.dimensions;
            }

            return object1;
        });

        form.setFieldsValue({ specialImages: listData });
        return { indexList: _fileNameInIndex, fileList: data };
    };

    useEffect(() => {
        if (!isCreateMode && formFieldValue?.[0]?.urls) {
            const { indexList, fileList } = setFileValue();
            setFileNameInIndex(indexList);
            setFileList(fileList);
        }
    }, [formFieldValue, isCreateMode]);

    const onPreview = async (file: UploadFile) => {
        let src = file?.response?.data || file.thumbUrl || file.url;
        if (!src && file.originFileObj) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                if (file.originFileObj) {
                    reader.readAsDataURL(file.originFileObj);
                }
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none', width: '400px' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload media</div>
        </button>
    );

    const tooltipMessages = [
        'Upload desktop PNG image',
        'Upload desktop WebP image',
        'Upload mobile PNG image',
        'Upload mobile WebP image',
    ];

    const getAngleText = (index: number) => {
        const angles = ['1st', '2nd', '3rd', '4th'];
        return `Upload images of ${angles[index] || `${index + 1}th`} angle.`;
    };

    return (
        <Form.List key={index} name={formInput.name}>
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map(({ key, name, ...restField }, index) => (
                        <Form.Item
                            key={key}
                            wrapperCol={{ span: 8, offset: 4 }}
                            labelCol={{ span: 4 }}
                            label={index === 0 ? <div>{formInput.label}</div> : <div></div>}
                            labelAlign='left'
                            colon={false}>
                            <div
                                style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}
                                className='imageUploadContainer'>
                                <div>{getAngleText(index)}</div>
                                <div style={{ display: 'flex', columnGap: '8px' }} className='imageUploadContainer'>
                                    {[1, 2, 3, 4].map((num, idx) => {
                                        const currentFileList = fileList.filter(
                                            (file) => file.name === `${index}${idx}`,
                                        );

                                        return (
                                            <Tooltip key={num} title={tooltipMessages[idx]} placement='top'>
                                                <Form.Item
                                                    key={num}
                                                    style={{ marginBottom: '0px', width: '100%' }}
                                                    {...restField}
                                                    name={[name, `image${num}`]}
                                                    valuePropName='fileList'
                                                    getValueFromEvent={(e) => {
                                                        if (Array.isArray(e)) {
                                                            return e;
                                                        }
                                                        return e && e.fileList;
                                                    }}
                                                    rules={[{ required: true, message: 'Please upload an image' }]}>
                                                    <Upload
                                                        name={`displayImage`}
                                                        listType='picture-card'
                                                        fileList={currentFileList}
                                                        action={`${BASE_API_URL}homepage/upload`}
                                                        onChange={(info) => onChange(info, `${index}${idx}`)}
                                                        onPreview={onPreview}>
                                                        {fileNameInIndex[index + '' + idx] ? null : uploadButton}
                                                    </Upload>
                                                </Form.Item>
                                            </Tooltip>
                                        );
                                    })}
                                    {!formInput.readonly && (
                                        <MinusCircleOutlined
                                            className='dynamic-delete-button offset'
                                            style={{ marginRight: '-50px', marginTop: '30px' }}
                                            onClick={() => remove(name)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Form.Item
                                    key={`dropdown-${index}`}
                                    style={{ marginBottom: '0px', flex: 1 }}
                                    {...restField}
                                    name={[name, `dropdown${index}`]}
                                    rules={[{ required: true, message: 'Please select dimensions' }]}>
                                    <Select placeholder='Select dimensions' options={dropDownOptions} />
                                </Form.Item>
                            </div>
                        </Form.Item>
                    ))}
                    {!formInput.readonly && (
                        <Form.Item
                            wrapperCol={{ offset: 8 }}
                            labelCol={{ span: 4 }}
                            style={{ position: 'relative' }}
                            required>
                            <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                                Add {formInput.label}
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    );
};

export default MultiSpatialImageUpload;
