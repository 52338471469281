// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiImageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; }

.uploadImage {
  border-width: 1px;
  border-color: black !important;
  border-style: dashed !important;
  background: none;
  width: 100px;
  height: 100px;
  border-radius: 5px; }
`, "",{"version":3,"sources":["webpack://./src/components/GalleryImageUpload/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sCAAsC,EAAA;;AAI1C;EACI,iBAAiB;EACjB,8BAA8B;EAC9B,+BAA+B;EAC/B,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,kBAAkB,EAAA","sourcesContent":[".multiImageContainer{\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    \n}\n\n.uploadImage{\n    border-width: 1px;\n    border-color: black !important;\n    border-style: dashed !important;\n    background: none;\n    width: 100px;\n    height: 100px;\n    border-radius: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
