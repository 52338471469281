import { errorMsg } from '../constants/messages';

const NewsletterFormData = [
    {
        title: 'Newsletter Details',
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Emails', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
];

export { NewsletterFormData };
