import { errorMsg } from '../constants/messages';

const QuotationsFormData = [
    {
        title: 'Quotations Details',
        name: 'fullName',
        label: 'Fullname',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Fullname', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'contact',
        label: 'Contact',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'company',
        label: 'Company',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Company' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'additionalComments',
        label: 'Additional Comments',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Additional Comments' },
        isTableHeader: true,
        isAccordian: false,
    },
];

export { QuotationsFormData };
